import _ from 'lodash'
import type { ViewerPlatformEssentials, AppEssentials } from '@wix/fe-essentials-viewer-platform'
import type { Connections, ControllerDataAPI, AppParams, WixCodeApi, PlatformAPI, PlatformServicesAPI, LivePreviewOptions, WidgetsClientSpecMapData, IModelsAPI } from '@wix/thunderbolt-symbols'
import type { IWixSelector } from '../modules/wixSelector'
import type { CreateSetPropsForOOI } from '../modules/setPropsManager'
import type { ISlotsManager } from '../modules/slotsManager'
import type { ControllerData } from '../types'
import type { PlatformApiProvider } from '../modules/platformApiProvider'
import type { IAppsUrls } from '../modules/appsUrls'
import { extractArtifactIdFromScriptUrl } from './extractArtifactIdFromScriptUrl'

export function createControllersParams(
	getSlotByName: IModelsAPI['getSlotByName'],
	createSingleRepeatedController: (slotName: string, compId: string, itemId: string) => Promise<() => Promise<void>>,
	createSetPropsForOOI: CreateSetPropsForOOI,
	controllersData: Array<ControllerData>,
	connections: Connections,
	wixSelector: IWixSelector,
	slotsManager: ISlotsManager,
	widgetsClientSpecMapData: WidgetsClientSpecMapData,
	appParams: AppParams,
	wixCodeApi: WixCodeApi,
	platformAppServicesApi: PlatformServicesAPI,
	platformApi: PlatformAPI,
	csrfToken: string,
	essentials: ViewerPlatformEssentials,
	appEssentials: AppEssentials,
	appsUrls: IAppsUrls,
	platformApiProvider: PlatformApiProvider,
	livePreviewOptions?: Partial<LivePreviewOptions>,
	isRepeatedSlots?: boolean
): Array<{ controllerCompId: string; controllerParams: ControllerDataAPI }> {
	return controllersData.map((controllerData) => {
		const { controllerType, compId, templateId, config, externalId, context, appDefinitionId } = controllerData
		const $wScope = context
			? wixSelector.create$wRepeaterScope({
					compId: context._internal.repeaterCompId,
					itemId: context.itemId,
			  })
			: wixSelector.create$wGlobalScope()

		const clientSpecMapData = widgetsClientSpecMapData[controllerType]

		const controllerScriptUrl = appsUrls.getControllerScriptUrl(appDefinitionId, controllerType)

		const controllerArtifactId = extractArtifactIdFromScriptUrl(controllerScriptUrl)

		return {
			controllerCompId: compId,
			controllerParams: {
				$w: context ? wixSelector.create$w(compId, wixCodeApi.telemetry).at(context, isRepeatedSlots) : wixSelector.create$w(compId, wixCodeApi.telemetry),
				getSlot: (slotName) => slotsManager.getSlot(compId, compId, slotName, wixSelector.getInstance, $wScope),
				compId: templateId || compId,
				name: clientSpecMapData?.widgetName || controllerType,
				componentFields: clientSpecMapData?.componentFields || {},
				type: controllerType,
				config,
				connections: _.flatMap(connections[compId], _.values),
				warmupData: null,
				appParams,
				platformAPIs: Object.assign(platformApi, platformAppServicesApi),
				wixCodeApi,
				csrfToken,
				setProps: createSetPropsForOOI(compId, context),
				externalId,
				essentials: essentials.createControllerEssentials(
					{
						widgetId: controllerType,
						compId,
						artifactId: controllerArtifactId,
					},
					appEssentials
				),
				livePreviewOptions,
				platformApiProvider,
				createRepeatedSlotAPI: async (slotName: string, repeatedItemId: string) => {
					const slotId = getSlotByName(compId, slotName)
					if (!slotId) {
						return {}
					}
					await createSingleRepeatedController(slotName, slotId, repeatedItemId).then((runControllersOnReadys: Function) => {
						return runControllersOnReadys()
					})
					const local$wScope = wixSelector.create$wRepeaterScope({ compId: slotId, itemId: repeatedItemId })
					return slotsManager.getSlot(compId, compId, slotName, wixSelector.getInstance, local$wScope, repeatedItemId)
				},
			},
		}
	})
}
